<template>
  <div class="page">
    <div class="title">加入社群理由</div>
    <div class="proposal_box">
      <div class="proposal_in_box">
        <v-input
          styleStr="background: transparent"
          v-model="applyReason"
          type="textarea"
          placeholder="请写下申请理由："
          :maxlength="30"
          :showWordLimit="true"
        />
      </div>
    </div>
    <div class="btn" @click="submit()">提交审核</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { applyJoinAssUrl, userInfoUrl } from "./api.js";
export default {
  name: "applyJoin",
  data() {
    return {
      applyReason: "",
      userInfo: null,
      associationId: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.associationId = this.$route.query.associationId;
    this.getUserInfo();
  },
  methods: {
    submit() {
      if (this.applyReason == "") {
        this.$toast("申请理由不能为空！");
        return;
      }
      let params = {
        userId: this.userId,
        userName: this.userInfo.userName,
        tenantId: this.tenantId,
        associationId: this.associationId,
        applyReason: this.applyReason,
      };
      this.$axios
        .post(`${applyJoinAssUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$router.replace({
              name: "association",
            });
          }
        });
    },
    getUserInfo() {
      let params = {
        userId: this.userId,
      };

      this.$axios
        .get(`${userInfoUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;

  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }

  .title {
    font-size: 32px;
    color: #333333;
    padding: 20px 56px;
  }

  .proposal_box {
    margin-top: 16px;
    box-sizing: border-box;
    padding: 0 41px;

    .proposal_in_box {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 16px;
    }
  }
}
</style>
